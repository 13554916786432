import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/layouts/legal.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><strong parentName="h1">{`Return Policy`}</strong></h1>
    <p>{`All sales are final and not eligible for return or exchanges. However, if we are out of your size and thus unable to ship, we will refund the transaction.`}</p>
    <h1><strong parentName="h1">{`Shipping Timeline`}</strong></h1>
    <p>{`For domestic orders (excluding Alaska and Hawaii) the last recommended day to place an order and receive by Christmas Day is December 19th
International order delivery cannot be guaranteed to arrive by Christmas Day`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      