import { Link } from 'gatsby'
import React from 'react'
import Layout from './index'
import * as s from '../styles/corporate.module.scss'
import logo from '../images/back.svg'

export default function LegalLayout({ children, pageContext }) {
  return (
    <Layout
      title={pageContext.frontmatter.title}
      path={`legal/${pageContext.frontmatter.slug}/`}
    >
      <>
        <div className={s.content}>
          <Link to='/' className={s.back_link}>
            <img className={s.back} src={logo} />
          </Link>
          <div className={s[pageContext.frontmatter.className]}>{children}</div>
        </div>
      </>
    </Layout>
  )
}
